// Author: Ryan Heath
// http://rpheath.com

(function($) {
    $.searchbox = {};

    $.extend(true, $.searchbox, {
        settings: {
            url: '/search',
            param: 'query',
            dom_id: '#results',
            delay: 100,
            loading_css: '#loading'
        },

        loading: function() {
            $($.searchbox.settings.loading_css).show()
        },

        resetTimer: function(timer) {
            if (timer) clearTimeout(timer)
        },

        idle: function() {
            $($.searchbox.settings.loading_css).hide()
        },

        process: function(terms) {

            var path = $.searchbox.settings.url.split('?'),
                query = [$.searchbox.settings.param, '=', terms].join(''),
                base = path[0], params = path[1], query_string = query

            if (params) query_string = [params.replace('&amp;', '&'), query].join('&')

            $.get([base, '?', query_string].join(''), function(data) {
                var results = jQuery.parseJSON(data.results);
                var item = data.item;
                var currentLetter;
                if(item == 'contributor'){
                    if(terms){
                        if($(window).width() < 993 ){
                            $('#contributor-results').removeClass('hidden-xs hidden-sm');
                        }
                    }else{
                        $('#contributor-results').addClass('hidden-xs hidden-sm');
                    }
                    $($.searchbox.settings.dom_id).html('');
                    $.each(results,function (key,value) {
                        var thisRegex = new RegExp('^[0-9a-zA-Z]{1}');
                        if(!thisRegex.test(value.first_name)){

                        }else {
                            currentLetter = value.last_name.charAt(0);
                            if($($.searchbox.settings.dom_id).find('p.'+currentLetter).length == 0){
                                $($.searchbox.settings.dom_id).append(" <p class='form-p "+currentLetter+"'>"+currentLetter+"</p>")
                            }
                        }
                        $($.searchbox.settings.dom_id).append("<a href='/archives/artistes/"+value.slug+"'>"+value.last_name + " , " + value.first_name +"</a>");
                    })
                }
                if(item == 'play') {
                    if(terms){
                        if($(window).width() < 993 ){
                            $('#play-results').removeClass('hidden-xs hidden-sm');
                        }
                    }else{
                        $('#play-results').addClass('hidden-xs hidden-sm');
                    }
                    $($.searchbox.settings.dom_id).html('');
                    $.each(results, function (key, value) {
                        var thisRegex = new RegExp('^[0-9a-zA-Z]{1}');
                        if (!thisRegex.test(value.title)) {

                        } else {
                            currentLetter = value.title.charAt(0);
                            if ($($.searchbox.settings.dom_id).find('p.' + currentLetter).length == 0) {
                                $($.searchbox.settings.dom_id).append(" <p class='form-p " + currentLetter + "'>" + currentLetter + "</p>")
                            }
                        }
                        $($.searchbox.settings.dom_id).append("<a href='/archives/pieces/" + value.slug + "'>" + value.title + "</a>");
                    })
                }

                if(item == 'season') {
                    if(terms){
                        if($(window).width() < 993 ){
                            $('#season-results').removeClass('hidden-xs hidden-sm');
                        }
                    }else{
                        $('#season-results').addClass('hidden-xs hidden-sm');
                    }
                    $($.searchbox.settings.dom_id).html('');
                    $.each(results, function (key, value) {
                         $($.searchbox.settings.dom_id).append("<a href='/archives/saisons/" + value.name + "'>" + value.name + "</a>");
                    })
                }
            })
        },

        start: function() {
            $(document).trigger('before.searchbox')
            $.searchbox.loading()
        },

        stop: function() {
            $.searchbox.idle()
            $(document).trigger('after.searchbox')
        }
    })

    $.fn.searchbox = function(config) {
        var settings = $.extend(true, $.searchbox.settings, config || {})

        $(document).trigger('init.searchbox')
        $.searchbox.idle();

        return this.each(function() {
            var $input = $(this);

            $input
                .focus()
                .ajaxStart(function() { $.searchbox.start() })
                .ajaxStop(function() { $.searchbox.stop() })
                .keyup(function() {
                    if ($input.val() != this.previousValue) {
                        $.searchbox.resetTimer(this.timer)

                        this.timer = setTimeout(function() {
                            $.searchbox.process($input.val())
                        }, $.searchbox.settings.delay)

                        this.previousValue = $input.val()
                    }
                })
        })
    }
})(jQuery);