var currentDay;
function getEvent(date, index) {
    $.ajax({
        url: '/get-event',
        type: "GET",
        data: {date: date, index: index},
        success: function (data) {
            currentDay = data.day;
            var currentDate = new Date(data.day);
            var match = /(\d+)\-(\d+)\-(\d+)/.exec(data.today);
            var todayYear = parseInt(match[1]);
            var todayMonth = parseInt(match[2]) - 1;
            var todayDay = parseInt(match[3]);
            var todaysDate = new Date(todayYear, todayMonth, todayDay);
            console.log('Day: ' + data.day + ' Date: ' + currentDate);
            console.log('Today: ' + data.today + ' Date: ' + todaysDate);
            if (currentDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
                dateForShow = 'AUJOURD’HUI ';
            } else {
                dateForShow = data.billeterieDayText;
            }

            $('#myEvents').html('');
            $('#myEvents-mobile').html('');
            $('#billeterie').html('');
            $('#billeterie-mobile').html('');
            var events = jQuery.parseJSON(data.eventsJson);
            if (events.length > 0) {
                $.each(events, function (key, value) {

                    if(value.box_link != ''){
                        $('#myEvents').append("<a href='" + value.url + "'> <h5>" + value.name + "</h5></a><p>" + value.location + " | " + value.duration + "</p>" + "<a href='" + value.box_link + "'><span style='color:#282865;font-weight: normal'>Acheter des billets</span></a>");
                        $('#myEvents-mobile').append("<a href='" + value.url + "'> <h5>" + value.name + "</h5></a><p>" + value.location + " | " + value.duration + "</p>" + "<a href='" + value.box_link + "'><span style='color:#282865;font-weight: normal'>Acheter des billets</span></a>");
                    } else {
                        $('#myEvents').append("<a href='" + value.url + "'> <h5>" + value.name + "</h5></a><p>" + value.location + " | " + value.duration + "</p>");
                        $('#myEvents-mobile').append("<a href='" + value.url + "'> <h5>" + value.name + "</h5></a><p>" + value.location + " | " + value.duration + "</p>");
                    }
                   
                });
            } else {
                if (dateForShow == 'AUJOURD’HUI ') {
                    $('#myEvents').append(" <h5>Pas d’événement </h5>")
                    $('#myEvents-mobile').append(" <h5>Pas d’événement </h5>")
                } else {
                    $('#myEvents').append(" <h5>Pas d’événement  </h5>")
                    $('#myEvents-mobile').append(" <h5>Pas d’événement  </h5>")
                }

            }
            $('#billeterie').append(data.billeterieText);
            $('#billeterie-mobile').append(data.billeterieText);

            if (index != 0) {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $(window).scrollTop($('#current-event-date-mobile').offset().top - 100);
                }
                $('#current-event-date').html(dateForShow + "<br> AU CTD’A");
                $('#current-event-date-mobile').html(dateForShow + "<br> AU CTD’A");
            }
        }
    });
}

var today = new Date();
var myYear = today.getFullYear();
var myMonth = today.getMonth() + 1;
var myDay = today.getDate();
var fulldate = myYear + "-" + myMonth + "-" + myDay;
getEvent(fulldate, 0);

$(document).ready(function () {
    $('.myIframe').css({"width": "100%", "height": "560px", "border": "none"});

    $('.multiple').hide();
    $('.multiple-mobile').hide();
    $('.consulter').on('click', function () {
        $('.multiple').toggle();
    });

    $('.consulter-mobile').on('click', function () {
        $('.multiple-mobile').toggle();
    });

    $('.nextDay').on('click', function () {
        getEvent(currentDay, 1);
    });

    $('.prewDay').on('click', function () {
        getEvent(currentDay, -1);
    });

    var offset,myScroll;
    var distance
    var setPositions = function () {

        if ($("#slideBarCenter").length || $("#slideBarLeft").length) {
            if ($("#slideBarCenter").length == 0) {
                var fixed = $("#slideBarLeft");
            } else {
                var fixed = $("#slideBarCenter");
            }
            offset = fixed.offset();
            distance = fixed.offset().top;
        }
    };



    $(window).scroll(function() {
        var distanceFromTop = $(document).scrollTop();
        if (distanceFromTop >= $('.header2').height()+45)
        {
            $('#slideBarLeft').addClass('fix-left');
            $('#slideBarCenter').addClass('fix-center');
        }
        else
        {
           $('#slideBarLeft').removeClass('fix-left');
           $('#slideBarCenter').removeClass('fix-center');
        }
    });

    function scrolly() {
        if ($('#sticky-anchor').offset()) {
            var offset = $('#sticky-anchor').offset().top;
            var myScroll = $(window).scrollTop();
            if (offset) {
                if (myScroll > offset - 50) {
                    $('#slideBarRight2').addClass('fix-right');
                } else {
                    $('#slideBarRight2').removeClass('fix-right');
                }
            }
        }
    }


    $(window).scroll(function () {
        $(window).scroll(scrolly);
        scrolly();
    });
    setPositions();
    $(window).resize(function () {
        if(myScroll < offset - 82){
            setPositions();
        }
    });

});
